/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    blockquote: "blockquote",
    p: "p",
    strong: "strong",
    a: "a",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "How it works?"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "\"HTML Forms are one of the main points of interaction between a user and a web site or application. They allow users to send data to the web site. Most of the time that data is sent to the ", React.createElement(_components.strong, null, "web server"), ", but the web page can also intercept it to use it on its own.\""), "\n", React.createElement(_components.p, null, "– ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Learn/HTML/Forms/Your_first_HTML_form"
  }, "Mozilla Developer Network")), "\n"), "\n", React.createElement(_components.p, null, "Modern tools let us create and run websites easier than ever. You can build and serve static websites entirely on free services like ", React.createElement(_components.a, {
    href: "https://pages.github.com/"
  }, "Github"), ", ", React.createElement(_components.a, {
    href: "https://about.gitlab.com/features/pages/"
  }, "Gitlab"), ", ", React.createElement(_components.a, {
    href: "https://www.netlify.com/"
  }, "Netlify"), ", ", React.createElement(_components.a, {
    href: "https://surge.sh/"
  }, "Surge"), " or ", React.createElement(_components.a, {
    href: "https://zeit.co/now"
  }, "Zeit's now.sh"), " to cite a few."), "\n", React.createElement(_components.p, null, "But more than often, adding HTML forms has to be in conjunction with a server part. It implies writing a backend application and find somewhere to run it. All this is quite a pain to do and can be costly, especially when you only want a contact form."), "\n", React.createElement(_components.p, null, "That's where ", React.createElement(_components.em, null, "Forwarder.cc"), " will try to help you. It will act as the missing backend for your beautiful static website without hassle. All you have to do, is writing your HTML form using you favourite client language and point it to us. We'll handle your visitors responses and send you the results!"), "\n", React.createElement(_components.p, null, "Each time one of your visitor fills a form, we charge you ", React.createElement(_components.em, null, "one credit"), ". If you just created your account, we gave you a bunch of them, so you can enjoy and play with our service. No monthy fees, no credit card hidden charges. But what happen if you run out of credits? We keep your messages and inform you that you have some of them waiting for you. They will be unlocked right away with a purchase of enough new credits."), "\n", React.createElement(_components.p, null, "Sounds easy? That's right! Now let's try to ", React.createElement(_components.a, {
    href: "/help/getting-started/creating-a-form"
  }, "create your first form"), " together!"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "/help/getting-started/creating-a-form"
  }, "Next: Creating a form")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
